import request from '@/utils/request'


// 查询动态图谱列表
export function listGraphCategory(query) {
    return request({
        url: '/dynamic/dynamic-graph-category/list',
        method: 'get',
        params: query
    })
}

// 查询动态图谱分页
export function pageGraphCategory(query) {
    return request({
        url: '/dynamic/dynamic-graph-category/page',
        method: 'get',
        params: query
    })
}

// 查询动态图谱详细
export function getGraphCategory(data) {
    return request({
        url: '/dynamic/dynamic-graph-category/detail',
        method: 'get',
        params: data
    })
}

// 新增动态图谱
export function addGraphCategory(data) {
    return request({
        url: '/dynamic/dynamic-graph-category/add',
        method: 'post',
        data: data
    })
}

// 修改动态图谱
export function updateGraphCategory(data) {
    return request({
        url: '/dynamic/dynamic-graph-category/edit',
        method: 'post',
        data: data
    })
}

// 删除动态图谱
export function delGraphCategory(data) {
    return request({
        url: '/dynamic/dynamic-graph-category/delete',
        method: 'post',
        data: data
    })
}
