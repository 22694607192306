import request from '@/utils/request'


// 查询动态图谱详情列表
export function listGraphDetail(query) {
    return request({
        url: '/dynamic/dynamic-graph-detail/list',
        method: 'get',
        params: query
    })
}

// 查询动态图谱详情分页
export function pageGraphDetail(query) {
    return request({
        url: '/dynamic/dynamic-graph-detail/page',
        method: 'get',
        params: query
    })
}

// 查询动态图谱详情详细
export function getGraphDetail(data) {
    return request({
        url: '/dynamic/dynamic-graph-detail/detail',
        method: 'get',
        params: data
    })
}

// 新增动态图谱详情
export function addGraphDetail(data) {
    return request({
        url: '/dynamic/dynamic-graph-detail/add',
        method: 'post',
        data: data
    })
}

// 修改动态图谱详情
export function updateGraphDetail(data) {
    return request({
        url: '/dynamic/dynamic-graph-detail/edit',
        method: 'post',
        data: data
    })
}

// 删除动态图谱详情
export function delGraphDetail(data) {
    return request({
        url: '/dynamic/dynamic-graph-detail/delete',
        method: 'post',
        data: data
    })
}
