import request from '@/utils/request'


// 查询动态图谱曲调列表
export function listGraphTune(query) {
    return request({
        url: '/dynamic/dynamic-graph-tune/list',
        method: 'get',
        params: query
    })
}

// 查询动态图谱曲调分页
export function pageGraphTune(query) {
    return request({
        url: '/dynamic/dynamic-graph-tune/page',
        method: 'get',
        params: query
    })
}

// 查询动态图谱曲调详细
export function getGraphTune(data) {
    return request({
        url: '/dynamic/dynamic-graph-tune/detail',
        method: 'get',
        params: data
    })
}

// 新增动态图谱曲调
export function addGraphTune(data) {
    return request({
        url: '/dynamic/dynamic-graph-tune/add',
        method: 'post',
        data: data
    })
}

// 修改动态图谱曲调
export function updateGraphTune(data) {
    return request({
        url: '/dynamic/dynamic-graph-tune/edit',
        method: 'post',
        data: data
    })
}

// 删除动态图谱曲调
export function delGraphTune(data) {
    return request({
        url: '/dynamic/dynamic-graph-tune/delete',
        method: 'post',
        data: data
    })
}
