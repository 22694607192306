export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
/**
 *
 * @param list 数据
 * @param root 根节点pid的值
 * @param replaceFields 替换字段名称 {id:"id",pid:"pid",children:"children"}
 * @param sort 排序 {field:"sort",way:"ASC或DESC"}
 * @returns {[]|Array}
 */
export function getTreeList(list, root, replaceFields, sortParams) {
  // debugger
  if (!list || list.length < 1) {
    return [];
  }
  var id = replaceFields.id || 'id';
  var pid = replaceFields.pid || 'pid';
  var children = replaceFields.children || 'children';

  var rootReslut = [];
  var map = {};
  list.forEach(e => {
    if (e[pid].split(",").includes(root) ) {
      rootReslut.push(e);
    }
    map[e[id]] = e;
  });
  for (var k in map) {

    var v = map[k];
    if (!v[pid].split(",").includes(root)) {

      for(let chPid of v[pid].split(",")){
        let childrenArr = map[chPid][children] || [];

        let chid = v;
        chid.pid = chPid
        childrenArr.push(chid);
        // debugger
        map[chPid][children] = childrenArr;
        // break;
      }
    }
  }
  if (sortParams) {
    rootReslut = sort(rootReslut, children, sortParams);
  }
  console.log(rootReslut)
  return rootReslut;
}

/**
 *
 * @param list 数据
 * @param children 此数据字段名称
 * @param sort  排序 {field:"sort",way:"ASC或DESC"}
 * @returns {*}
 */
export function sort(list, children, sortParams) {
  var field = sortParams.field;
  var way = sortParams.way || "ASC";
  if (!field) {
    return list;
  }
  var res = way === "ASC" ? 1 : -1;

  list.forEach(e => {
    if (e[children] && e[children].length > 0) {
      e[children] = sort(e[children], children, sort);
    }
  });

  return list.sort((a, b) => {
    var av = a[field];
    var bv = b[field];
    if (av < bv) {
      return res * -1
    }

    if (av > bv) {
      return res * 1
    }
    return 0;
  });


}